var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("chart-card", {
    attrs: {
      title: "Example Line Card",
      chartData: _vm.chartData,
      height: _vm.$vuetify.breakpoint.smAndDown ? "200px" : undefined
    },
    scopedSlots: _vm._u([
      {
        key: "overview",
        fn: function() {
          return [_c("overview", { attrs: { diff: "+90", total: "148" } })]
        },
        proxy: true
      }
    ])
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }