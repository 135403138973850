<template>
  <fragment>
    <content-loader
      :width="700"
      :height="95"
      :speed="2"
      primaryColor="#f3f3f3"
      secondaryColor="#ecebeb"
    >
      <!-- dismissable alert and button -->
      <rect x="4" y="0" rx="0" ry="0" width="700" height="55" />
      <rect x="535" y="65" rx="0" ry="0" width="215" height="30" />
    </content-loader>
    <table-placeholder :rows="5" :width="700" />
  </fragment>
</template>

<script>
import { ContentLoader } from 'vue-content-loader';
import TablePlaceholder from '@/components/Placeholder/Table';

export default {
  components: { ContentLoader, TablePlaceholder },
};
</script>
