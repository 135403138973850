<template>
  <card :title="title" @expanded="onExpand">
    <template #overview>
      <slot name="overview" v-bind:overview="$scopedSlots.overview"></slot>
    </template>
    <template>
      <apexchart
        class="chart"
        :width="width"
        :height="height"
        :options="combinedOptions"
        :series="chartData.series"
      ></apexchart>
    </template>
    <template #expanded>
      <slot name="expanded" v-bind:expanded="$scopedSlots.expanded"></slot>
    </template>
  </card>
</template>

<script>
import merge from 'lodash/merge';
import VueApexCharts from 'vue-apexcharts';
import Card from '@/components/Analytics/Card';

export default {
  components: {
    apexchart: VueApexCharts,
    Card,
  },
  props: {
    title: {
      type: String,
      required: false,
      default: '',
    },
    chartData: {
      type: Object,
      required: true,
    },
    height: {
      type: String,
      required: false,
      default: 'auto',
    },
    width: {
      type: String,
      required: false,
      default: '100%',
    },
  },
  data: () => ({
    // TODO: default style
    defaultChartOptions: {},
  }),
  computed: {
    combinedOptions() {
      return merge(this.defaultChartOptions, this.chartData.options);
    },
  },
  methods: {
    onExpand(expanded) {
      this.$emit('expanded', expanded);
    },
  },
};
</script>

<style scoped>
.chart >>> .apexcharts-toolbar {
  z-index: 1;
}
</style>
