var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "content-loader",
    {
      style: _vm.style,
      attrs: {
        width: _vm.width,
        height: _vm.height,
        speed: 2,
        primaryColor: "#f3f3f3",
        secondaryColor: "#ecebeb"
      }
    },
    [
      _c("rect", {
        attrs: {
          x: "0",
          y: "0",
          rx: "3",
          ry: "3",
          width: _vm.width,
          height: "2"
        }
      }),
      _c("rect", {
        attrs: {
          x: "0",
          y: "0",
          rx: "3",
          ry: "3",
          width: "2",
          height: _vm.height
        }
      }),
      _c("rect", {
        attrs: {
          x: _vm.width - 2,
          y: "0",
          rx: "3",
          ry: "3",
          width: "2",
          height: _vm.height
        }
      }),
      _c("rect", {
        attrs: {
          x: "0",
          y: _vm.height - 2,
          rx: "3",
          ry: "3",
          width: _vm.width,
          height: "2"
        }
      }),
      _c("rect", {
        attrs: {
          x: "0",
          y: "45",
          rx: "3",
          ry: "3",
          width: _vm.width,
          height: "2"
        }
      }),
      _c("rect", {
        attrs: {
          x: "10",
          y: "10",
          rx: "3",
          ry: "3",
          width: _vm.widthOfColumn,
          height: "25"
        }
      }),
      _c("rect", {
        attrs: {
          x: _vm.findXPosition(1),
          y: "10",
          rx: "3",
          ry: "3",
          width: _vm.widthOfColumn,
          height: "25"
        }
      }),
      _c("rect", {
        attrs: {
          x: _vm.findXPosition(2),
          y: "10",
          rx: "3",
          ry: "3",
          width: _vm.widthOfColumn,
          height: "25"
        }
      }),
      _c("rect", {
        attrs: {
          x: _vm.findXPosition(3),
          y: "10",
          rx: "3",
          ry: "3",
          width: _vm.widthOfColumn,
          height: "25"
        }
      }),
      _c("rect", {
        attrs: {
          x: _vm.findXPosition(4),
          y: "10",
          rx: "3",
          ry: "3",
          width: _vm.widthOfColumn,
          height: "25"
        }
      }),
      _vm._l(_vm.rows, function(index) {
        return _c("fragment", { key: index }, [
          _c("rect", {
            attrs: {
              x: "10",
              y: index * _vm.rowHeight + _vm.rowPadding * 2,
              rx: "3",
              ry: "3",
              width: _vm.widthOfColumn,
              height: "25"
            }
          }),
          _c("rect", {
            attrs: {
              x: _vm.findXPosition(1),
              y: index * _vm.rowHeight + _vm.rowPadding * 2,
              rx: "3",
              ry: "3",
              width: _vm.widthOfColumn,
              height: "25"
            }
          }),
          _c("rect", {
            attrs: {
              x: _vm.findXPosition(2),
              y: index * _vm.rowHeight + _vm.rowPadding * 2,
              rx: "3",
              ry: "3",
              width: _vm.widthOfColumn,
              height: "25"
            }
          }),
          _c("rect", {
            attrs: {
              x: _vm.findXPosition(3),
              y: index * _vm.rowHeight + _vm.rowPadding * 2,
              rx: "3",
              ry: "3",
              width: _vm.widthOfColumn,
              height: "25"
            }
          }),
          _c("rect", {
            attrs: {
              x: _vm.findXPosition(4),
              y: index * _vm.rowHeight + _vm.rowPadding * 2,
              rx: "3",
              ry: "3",
              width: _vm.widthOfColumn,
              height: "25"
            }
          })
        ])
      })
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }