<template>
  <chart-card
    title="Example Bar Card"
    :chartData="chartData"
    height="350px"
    @expanded="handleExpanded"
  >
    <template #overview>
      <overview diff="52%" total="$11,054.87"></overview>
    </template>
    <template #expanded>
      <div class="mb-3">
        <loading-wrapper :loading="!expandedDataLoaded" :size="50">
          <p>{{ expandedData.title }}</p>
          <p>{{ expandedData.body }}</p>
        </loading-wrapper>
      </div>
    </template>
  </chart-card>
</template>

<script>
import Overview from '@/components/Analytics/Card/Overview';
import ChartCard from '@/components/Analytics/Card/Chart';

export default {
  components: {
    ChartCard,
    Overview,
  },
  data: () => ({
    chartData: {
      series: [
        {
          data: [400, 430, 448, 470, 540, 580, 690, 1100, 1200, 1380],
        },
      ],
      options: {
        chart: {
          type: 'bar',
        },
        plotOptions: {
          bar: {
            horizontal: true,
          },
        },
        dataLabels: {
          enabled: false,
        },
        xaxis: {
          categories: [
            'South Korea',
            'Canada',
            'United Kingdom',
            'Netherlands',
            'Italy',
            'France',
            'Japan',
            'United States',
            'China',
            'Germany',
          ],
        },
        title: {
          text: 'Sales by Area',
        },
      },
    },
    expandedDataLoaded: false,
    expandedData: {
      title: null,
      body: null,
    },
  }),
  methods: {
    handleExpanded(expanded) {
      if (expanded && !this.expandedDataLoaded) {
        window.setTimeout(() => {
          this.expandedDataLoaded = true;
          this.expandedData = {
            title: 'Expanded Data loaded!',
            body: 'Here is the extra data',
          };
        }, 2000);
      }
    },
  },
};
</script>
