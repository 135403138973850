import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VDatePicker } from 'vuetify/lib/components/VDatePicker';
import { VFlex } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VLayout } from 'vuetify/lib/components/VGrid';
import { VMenu } from 'vuetify/lib/components/VMenu';
import { VSelect } from 'vuetify/lib/components/VSelect';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    VMenu,
    {
      attrs: {
        "close-on-content-click": false,
        "offset-y": "",
        "nudge-bottom": "10px",
        "z-index": "12"
      },
      on: { input: _vm.onMenuChange },
      scopedSlots: _vm._u([
        {
          key: "activator",
          fn: function(ref) {
            var on = ref.on
            return [
              _c(
                VBtn,
                _vm._g({ staticClass: "ml-0" }, on),
                [
                  _c(VIcon, { staticClass: "mr-2" }, [
                    _vm._v("\n        mdi-calendar\n      ")
                  ]),
                  _vm._v("\n      " + _vm._s(_vm.buttonText) + "\n    ")
                ],
                1
              )
            ]
          }
        }
      ]),
      model: {
        value: _vm.menu,
        callback: function($$v) {
          _vm.menu = $$v
        },
        expression: "menu"
      }
    },
    [
      _c(
        VCard,
        { staticClass: "card" },
        [
          _c(
            VCardText,
            [
              _c(
                VLayout,
                { attrs: { row: "", wrap: "" } },
                [
                  _c(
                    VFlex,
                    [
                      _c("span", { staticClass: "input-title" }, [
                        _vm._v("Date Range")
                      ]),
                      _c(VSelect, {
                        staticClass: "date-picker-select",
                        attrs: { outline: "", items: _vm.presetOptions },
                        on: { input: _vm.onPreset },
                        model: {
                          value: _vm.presetSelection,
                          callback: function($$v) {
                            _vm.presetSelection = $$v
                          },
                          expression: "presetSelection"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                VLayout,
                { attrs: { row: "" } },
                [
                  _c(
                    VFlex,
                    { staticClass: "mr-2", attrs: { xs6: "" } },
                    [
                      _c("span", { staticClass: "input-title" }, [
                        _vm._v("Starting")
                      ]),
                      _c(VTextField, {
                        staticClass: "date-picker-text",
                        attrs: {
                          outline: "",
                          readonly: "",
                          value: _vm.startingDate
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    VFlex,
                    { attrs: { xs6: "" } },
                    [
                      _c("span", { staticClass: "input-title" }, [
                        _vm._v("Ending")
                      ]),
                      _c(VTextField, {
                        staticClass: "date-picker-text",
                        attrs: {
                          outline: "",
                          readonly: "",
                          value: _vm.endingDate
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                VLayout,
                { attrs: { row: "", wrap: "" } },
                [
                  _c(
                    VFlex,
                    [
                      _c(VDatePicker, {
                        staticClass: "date-picker",
                        attrs: {
                          multiple: "",
                          value: _vm.tmpDates,
                          max: _vm.maxDate,
                          width: "400"
                        },
                        on: { input: _vm.changedDate }
                      })
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            VCardActions,
            { staticClass: "justify-space-between" },
            [
              _c(VBtn, { on: { click: _vm.onCancel } }, [_vm._v("Cancel")]),
              _c(
                VBtn,
                { attrs: { color: "primary" }, on: { click: _vm.onApply } },
                [_vm._v("Apply")]
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }