<template>
  <content-loader
    :width="width"
    :height="height"
    :speed="2"
    :style="style"
    primaryColor="#f3f3f3"
    secondaryColor="#ecebeb"
  >
    <!-- outline -->
    <rect x="0" y="0" rx="3" ry="3" :width="width" height="2" />
    <rect x="0" y="0" rx="3" ry="3" width="2" :height="height" />
    <rect :x="width - 2" y="0" rx="3" ry="3" width="2" :height="height" />
    <rect x="0" :y="height - 2" rx="3" ry="3" :width="width" height="2" />
    <rect x="0" y="45" rx="3" ry="3" :width="width" height="2" />

    <!-- table head text -->
    <rect x="10" y="10" rx="3" ry="3" :width="widthOfColumn" height="25" />
    <rect :x="findXPosition(1)" y="10" rx="3" ry="3" :width="widthOfColumn" height="25" />
    <rect :x="findXPosition(2)" y="10" rx="3" ry="3" :width="widthOfColumn" height="25" />
    <rect :x="findXPosition(3)" y="10" rx="3" ry="3" :width="widthOfColumn" height="25" />
    <rect :x="findXPosition(4)" y="10" rx="3" ry="3" :width="widthOfColumn" height="25" />

    <!-- table body rows -->
    <fragment v-for="index in rows" :key="index">
      <rect
        x="10"
        :y="index * rowHeight + rowPadding * 2"
        rx="3"
        ry="3"
        :width="widthOfColumn"
        height="25"
      />
      <rect
        :x="findXPosition(1)"
        :y="index * rowHeight + rowPadding * 2"
        rx="3"
        ry="3"
        :width="widthOfColumn"
        height="25"
      />
      <rect
        :x="findXPosition(2)"
        :y="index * rowHeight + rowPadding * 2"
        rx="3"
        ry="3"
        :width="widthOfColumn"
        height="25"
      />
      <rect
        :x="findXPosition(3)"
        :y="index * rowHeight + rowPadding * 2"
        rx="3"
        ry="3"
        :width="widthOfColumn"
        height="25"
      />
      <rect
        :x="findXPosition(4)"
        :y="index * rowHeight + rowPadding * 2"
        rx="3"
        ry="3"
        :width="widthOfColumn"
        height="25"
      />
    </fragment>
  </content-loader>
</template>

<script>
import { ContentLoader } from 'vue-content-loader';

export default {
  props: {
    columns: {
      type: Number,
      default: 5,
    },
    rows: {
      type: Number,
      default: 5,
    },
    width: {
      type: Number,
      default: 200,
    },
  },
  components: { ContentLoader },
  data: () => ({
    columnPadding: 20,
    tableHeadHeight: 45,
    rowPadding: 10,
    rowHeight: 40,
  }),
  computed: {
    height() {
      // head height + (row height * rows) + bottom padding
      return this.tableHeadHeight + this.rowHeight * this.rows + this.rowPadding;
    },
    style() {
      return {
        'max-width': this.width,
      };
    },
    widthOfColumn() {
      return this.width / this.columns - this.columnPadding;
    },
  },
  methods: {
    findXPosition(columnNo) {
      // width of column + padding + initial X pos
      return this.widthOfColumn * columnNo + this.columnPadding * columnNo + 10;
    },
  },
};
</script>
