<template>
  <v-card class="card">
    <v-card-title class="title">
      <span class="card-title">{{ title }}</span>
    </v-card-title>
    <v-card-text>
      <v-layout row v-if="$slots.overview">
        <v-flex>
          <slot name="overview"></slot>
        </v-flex>
      </v-layout>
      <v-layout row>
        <v-flex>
          <slot></slot>
        </v-flex>
      </v-layout>
    </v-card-text>
    <v-card-actions v-if="$slots.expanded" class="actions" transition="slide-y-transition">
      <v-layout row>
        <v-flex>
          <slot v-if="expand" name="expanded"></slot>
        </v-flex>
      </v-layout>
      <v-layout row justify-center>
        <v-btn flat small class="expand-button" @click="onExpand">
          {{ expand ? 'Close' : 'Show All' }}
          <v-icon>{{ expand ? 'mdi-menu-up' : 'mdi-menu-down' }}</v-icon>
        </v-btn>
      </v-layout>
    </v-card-actions>
  </v-card>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      required: false,
      default: '',
    },
  },
  data: () => ({
    expand: false,
  }),
  methods: {
    onExpand() {
      this.expand = !this.expand;
      this.$emit('expanded', this.expand);
    },
  },
};
</script>

<style scoped>
.actions {
  display: block;
}

.card {
  margin-bottom: 16px;
}

.card-title {
  border-bottom: 2px dotted #ccc;
}

.expand-button {
  color: #0d73d8;
  text-transform: initial;
}
</style>
