import { VAlert } from 'vuetify/lib/components/VAlert';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VFlex } from 'vuetify/lib/components/VGrid';
import { VLayout } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';
import { VTooltip } from 'vuetify/lib/components/VTooltip';

var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "card",
    { attrs: { title: "Mobile & Exact Reconciliation Dashboard" } },
    [
      _c(
        "placeholder",
        {
          attrs: { loading: _vm.loading },
          scopedSlots: _vm._u([
            {
              key: "loading",
              fn: function() {
                return [_c("loader")]
              },
              proxy: true
            }
          ])
        },
        [
          _c(
            VLayout,
            { attrs: { row: "" } },
            [
              _c(
                VFlex,
                [
                  _c(
                    VAlert,
                    {
                      staticClass: "alert",
                      attrs: {
                        dismissible: "",
                        value: _vm.noOfDescrepencies > 0,
                        type: "error",
                        icon: "mdi-alert-octagon"
                      }
                    },
                    [
                      _vm._v(
                        "\n          We have identified " +
                          _vm._s(_vm.noOfDescrepencies) +
                          " amount of units with sale discrepancies\n          within the dates you have selected.\n        "
                      )
                    ]
                  ),
                  _c(
                    VAlert,
                    {
                      staticClass: "alert",
                      attrs: {
                        dismissible: "",
                        value: _vm.noOfDescrepencies === 0,
                        type: "success",
                        icon: "mdi-thumb-up"
                      }
                    },
                    [
                      _vm._v(
                        "\n          Great news, you have no sales discrepancies within the dates you have selected!\n        "
                      )
                    ]
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            VLayout,
            { attrs: { row: "", "justify-end": "" } },
            [
              _c(
                VBtn,
                {
                  attrs: {
                    color: "primary",
                    disabled: _vm.noOfDescrepencies === 0,
                    small: "",
                    depressed: ""
                  },
                  on: {
                    click: function($event) {
                      _vm.summaryShowDescrepencies = !_vm.summaryShowDescrepencies
                    }
                  }
                },
                [
                  _vm._v(
                    _vm._s(
                      _vm.summaryShowDescrepencies
                        ? "Show all Data"
                        : "Show Discrepancies"
                    )
                  )
                ]
              )
            ],
            1
          ),
          _c(
            VLayout,
            { attrs: { row: "" } },
            [
              _c(
                VFlex,
                { attrs: { xs4: "" } },
                [
                  _c(VTextField, {
                    attrs: {
                      "prepend-inner-icon": "mdi-magnify",
                      label: "Filter by ID or Date"
                    },
                    model: {
                      value: _vm.filter,
                      callback: function($$v) {
                        _vm.filter = $$v
                      },
                      expression: "filter"
                    }
                  })
                ],
                1
              )
            ],
            1
          ),
          _c(
            VLayout,
            { attrs: { row: "" } },
            [
              _c(
                VFlex,
                [
                  _c(VDataTable, {
                    staticClass: "summary-table",
                    attrs: {
                      headers: _vm.summaryHeaders,
                      items: _vm.summaryData
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "items",
                        fn: function(ref) {
                          var item = ref.item
                          return [
                            _c(
                              "tr",
                              {
                                staticClass: "timeslot-row",
                                class: item.diff > 0 && "has-discrepancy",
                                on: {
                                  click: function($event) {
                                    return _vm.onRowClick(item)
                                  }
                                }
                              },
                              [
                                _c("td", [
                                  _vm._v(_vm._s(_vm._f("ellipsis")(item.id)))
                                ]),
                                _c("td", [_vm._v(_vm._s(item.date))]),
                                _c("td", { staticClass: "text-xs-right" }, [
                                  _vm._v(_vm._s(_vm._f("currency")(item.exact)))
                                ]),
                                _c("td", { staticClass: "text-xs-right" }, [
                                  _vm._v(
                                    _vm._s(_vm._f("currency")(item.mobile))
                                  )
                                ]),
                                _c("td", { staticClass: "text-xs-right" }, [
                                  _vm._v(_vm._s(_vm._f("currency")(item.diff)))
                                ])
                              ]
                            )
                          ]
                        }
                      }
                    ])
                  })
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _vm.showTransactionsTable
        ? _c(
            VLayout,
            { staticClass: "mt-5", attrs: { row: "", wrap: "" } },
            [
              _c(
                VFlex,
                { attrs: { xs12: "" } },
                [
                  _c(
                    VLayout,
                    { attrs: { row: "", "align-center": "" } },
                    [
                      _c(
                        VFlex,
                        [
                          _c(
                            VTooltip,
                            {
                              attrs: { bottom: "" },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "activator",
                                    fn: function(ref) {
                                      var on = ref.on
                                      return [
                                        _c("span", _vm._g({}, on), [
                                          _vm._v(
                                            "You have selected to see details for units:\n                " +
                                              _vm._s(
                                                _vm.transactionTableUnitsClicked()
                                              )
                                          )
                                        ])
                                      ]
                                    }
                                  }
                                ],
                                null,
                                false,
                                1868718889
                              )
                            },
                            [
                              _c("span", [
                                _vm._v(
                                  _vm._s(_vm.transactionTableUnitsClicked(true))
                                )
                              ])
                            ]
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                VFlex,
                { attrs: { xs12: "" } },
                [
                  _c(VDataTable, {
                    staticClass: "transactions-table",
                    attrs: {
                      headers: _vm.transactionHeaders,
                      items: _vm.transactionsData,
                      loading: _vm.transactionsLoading
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "items",
                          fn: function(ref) {
                            var item = ref.item
                            return [
                              _c(
                                "tr",
                                {
                                  staticClass: "timeslot-row",
                                  class:
                                    item.mob_total !== item.exact_totalamount &&
                                    "has-discrepancy"
                                },
                                [
                                  _c("td", [
                                    _vm._v(_vm._s(_vm._f("ellipsis")(item.id)))
                                  ]),
                                  _c("td", [_vm._v(_vm._s(item.exact_tag))]),
                                  _c("td", [
                                    _vm._v(_vm._s(item.exact_ordertime))
                                  ]),
                                  _c("td", [
                                    _vm._v(_vm._s(item.exact_cardtype))
                                  ]),
                                  _c("td", [
                                    _vm._v(_vm._s(item.exact_pickupname))
                                  ]),
                                  _c("td", [
                                    _vm._v(_vm._s(item.exact_transactiontype))
                                  ]),
                                  _c("td", { staticClass: "text-xs-right" }, [
                                    _vm._v(
                                      _vm._s(
                                        _vm._f("currency")(
                                          item.exact_totalamount
                                        )
                                      )
                                    )
                                  ]),
                                  _c("td", [
                                    _vm._v(_vm._s(item.exact_merchantname))
                                  ]),
                                  _c("td", [_vm._v(_vm._s(item.exact_status))]),
                                  _c("td", [_vm._v(_vm._s(item.mob_orderid))]),
                                  _c("td", [
                                    _vm._v(_vm._s(item.mob_ordertime))
                                  ]),
                                  _c("td", [
                                    _vm._v(_vm._s(item.mob_paymenttype))
                                  ]),
                                  _c("td", [
                                    _vm._v(_vm._s(item.mob_pickupname))
                                  ]),
                                  _c("td", { staticClass: "text-xs-right" }, [
                                    _vm._v(
                                      _vm._s(_vm._f("currency")(item.mob_total))
                                    )
                                  ])
                                ]
                              )
                            ]
                          }
                        }
                      ],
                      null,
                      false,
                      1033442671
                    )
                  })
                ],
                1
              )
            ],
            1
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }