import { VBtn } from 'vuetify/lib/components/VBtn';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VLayout } from 'vuetify/lib/components/VGrid';

var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "card",
    { attrs: { title: "Tracker Tags" } },
    [
      _c(
        VLayout,
        { attrs: { row: "", "justify-end": "" } },
        [
          _c(
            VBtn,
            {
              attrs: { color: "primary", small: "", depressed: "" },
              on: { click: _vm.addNewTrackerTag }
            },
            [
              _c(VIcon, { attrs: { left: "", small: "" } }, [
                _vm._v("mdi-plus")
              ]),
              _vm._v("\n      New Tracker Tag\n    ")
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }