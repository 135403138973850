<template>
  <v-menu
    v-model="menu"
    @input="onMenuChange"
    :close-on-content-click="false"
    offset-y
    nudge-bottom="10px"
    z-index="12"
  >
    <template v-slot:activator="{ on }">
      <v-btn class="ml-0" v-on="on">
        <v-icon class="mr-2">
          mdi-calendar
        </v-icon>
        {{ buttonText }}
      </v-btn>
    </template>
    <v-card class="card">
      <v-card-text>
        <v-layout row wrap>
          <v-flex>
            <span class="input-title">Date Range</span>
            <v-select
              outline
              class="date-picker-select"
              :items="presetOptions"
              v-model="presetSelection"
              @input="onPreset"
            ></v-select>
          </v-flex>
        </v-layout>
        <v-layout row>
          <v-flex class="mr-2" xs6>
            <span class="input-title">Starting</span>
            <v-text-field
              outline
              readonly
              class="date-picker-text"
              :value="startingDate"
            ></v-text-field>
          </v-flex>
          <v-flex xs6>
            <span class="input-title">Ending</span>
            <v-text-field
              outline
              readonly
              class="date-picker-text"
              :value="endingDate"
            ></v-text-field>
          </v-flex>
        </v-layout>
        <v-layout row wrap>
          <v-flex>
            <v-date-picker
              multiple
              :value="tmpDates"
              :max="maxDate"
              @input="changedDate"
              class="date-picker"
              width="400"
            ></v-date-picker>
          </v-flex>
        </v-layout>
      </v-card-text>
      <v-card-actions class="justify-space-between">
        <v-btn @click="onCancel">Cancel</v-btn>
        <v-btn color="primary" @click="onApply">Apply</v-btn>
      </v-card-actions>
    </v-card>
  </v-menu>
</template>

<script>
import { DateTime } from 'luxon';
import { SMART_ANALYTICS_DATE_PRESETS } from '@/constants';

export default {
  props: {
    dates: {
      Type: Object,
      required: true,
      validator: (config) => ['start', 'end', 'preset'].every((key) => key in config),
    },
  },
  data: () => ({
    tmpDates: [],
    presetSelection: 'custom',
    presetRanges: SMART_ANALYTICS_DATE_PRESETS,
    maxDate: DateTime.local().toISO(),
    menu: false,
  }),
  computed: {
    buttonText() {
      if (this.dates.preset === 'custom') {
        return `
          ${DateTime.fromISO(this.dates.start).toLocaleString(
            DateTime.DATE_SHORT,
          )} - ${DateTime.fromISO(this.dates.end).toLocaleString(DateTime.DATE_SHORT)}
        `;
      }

      return this.presetRanges[this.dates.preset].text;
    },
    presetOptions() {
      return Object.values(this.presetRanges).map((option) => ({
        text: option.text,
        value: option.value,
      }));
    },
    startingDate() {
      return this.tmpDates[0]
        ? DateTime.fromISO(this.tmpDates[0]).toLocaleString(DateTime.DATE_SHORT)
        : '';
    },
    endingDate() {
      return this.tmpDates[0]
        ? DateTime.fromISO(this.tmpDates[this.tmpDates.length - 1]).toLocaleString(
            DateTime.DATE_SHORT,
          )
        : '';
    },
  },
  methods: {
    onMenuChange(isOpen) {
      if (isOpen) {
        this.tmpDates = this.generateDatesInRange([this.dates.start, this.dates.end]);
        this.presetSelection = this.dates.preset;
      }
      return null;
    },
    changedDate(val = []) {
      let new_dates = val.sort();
      const prevDates = [...this.tmpDates];

      if (new_dates.length === 0 && prevDates.length === 1) {
        new_dates = prevDates;
      }
      if (new_dates.length < prevDates.length) {
        new_dates = prevDates.filter((prevDate) => !new_dates.includes(prevDate));
      }
      if (new_dates.length > prevDates.length && prevDates.length > 1) {
        new_dates = new_dates.filter((prevDate) => !prevDates.includes(prevDate));
      }

      this.tmpDates = this.generateDatesInRange(new_dates);
      this.presetSelection = 'custom';
      return null;
    },
    generateDatesInRange(_dates = []) {
      const start = DateTime.fromISO(_dates[0]);
      const end = DateTime.fromISO(_dates[_dates.length - 1]);
      const rangeDates = [];

      if (start.isValid && end.isValid) {
        let dpDate = start;
        do {
          rangeDates.push(dpDate.toISODate());
          dpDate = dpDate.plus({ days: 1 });
        } while (dpDate <= end);
      }
      return rangeDates;
    },
    onApply() {
      this.$emit('datesUpdated', {
        preset: this.presetSelection,
        start: this.tmpDates[0],
        end: this.tmpDates[this.tmpDates.length - 1],
      });
      this.menu = false;
      this.tmpDates = [];
      this.presetSelection = 'custom';
      return null;
    },
    onCancel() {
      this.tmpDates = [];
      this.menu = false;
      return null;
    },
    onPreset(val) {
      const range = this.presetRanges[val].range();
      this.tmpDates = this.generateDatesInRange(range);
      return null;
    },
  },
};
</script>

<style scoped>
.card {
  padding-top: 16px;
}

.date-picker-text >>> .v-input__slot {
  min-height: unset;
}

.date-picker-text >>> input {
  margin-top: 0;
}

.date-picker-select >>> .v-input__slot {
  min-height: unset;
}

.date-picker-select >>> .v-select__slot .v-select__selections {
  padding-top: 0;
}

.date-picker-select >>> .v-input__append-inner {
  margin-top: 5px;
}

.date-picker {
  box-shadow: none;
  width: 100%;
}

.date-picker >>> .v-picker__title {
  display: none;
}

.date-picker >>> .v-picker__body {
  max-width: 100%;
}

.input-title {
  display: inline-block;
  margin-bottom: 8px;
}
</style>
