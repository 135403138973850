var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "fragment",
    [
      _c(
        "content-loader",
        {
          attrs: {
            width: 700,
            height: 95,
            speed: 2,
            primaryColor: "#f3f3f3",
            secondaryColor: "#ecebeb"
          }
        },
        [
          _c("rect", {
            attrs: {
              x: "4",
              y: "0",
              rx: "0",
              ry: "0",
              width: "700",
              height: "55"
            }
          }),
          _c("rect", {
            attrs: {
              x: "535",
              y: "65",
              rx: "0",
              ry: "0",
              width: "215",
              height: "30"
            }
          })
        ]
      ),
      _c("table-placeholder", { attrs: { rows: 5, width: 700 } })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }