<template>
  <card title="Mobile & Exact Reconciliation Dashboard">
    <placeholder :loading="loading">
      <template #loading>
        <loader />
      </template>
      <v-layout row>
        <v-flex>
          <v-alert
            dismissible
            class="alert"
            :value="noOfDescrepencies > 0"
            type="error"
            icon="mdi-alert-octagon"
          >
            We have identified {{ noOfDescrepencies }} amount of units with sale discrepancies
            within the dates you have selected.
          </v-alert>
          <v-alert
            dismissible
            class="alert"
            :value="noOfDescrepencies === 0"
            type="success"
            icon="mdi-thumb-up"
          >
            Great news, you have no sales discrepancies within the dates you have selected!
          </v-alert>
        </v-flex>
      </v-layout>
      <v-layout row justify-end>
        <v-btn
          color="primary"
          @click="summaryShowDescrepencies = !summaryShowDescrepencies"
          :disabled="noOfDescrepencies === 0"
          small
          depressed
          >{{ summaryShowDescrepencies ? 'Show all Data' : 'Show Discrepancies' }}</v-btn
        >
      </v-layout>
      <v-layout row>
        <v-flex xs4>
          <v-text-field
            prepend-inner-icon="mdi-magnify"
            label="Filter by ID or Date"
            v-model="filter"
          />
        </v-flex>
      </v-layout>
      <v-layout row>
        <v-flex>
          <v-data-table class="summary-table" :headers="summaryHeaders" :items="summaryData">
            <template v-slot:items="{ item }">
              <tr
                class="timeslot-row"
                :class="item.diff > 0 && 'has-discrepancy'"
                @click="onRowClick(item)"
              >
                <td>{{ item.id | ellipsis }}</td>
                <td>{{ item.date }}</td>
                <td class="text-xs-right">{{ item.exact | currency }}</td>
                <td class="text-xs-right">{{ item.mobile | currency }}</td>
                <td class="text-xs-right">{{ item.diff | currency }}</td>
              </tr>
            </template>
          </v-data-table>
        </v-flex>
      </v-layout>
    </placeholder>
    <v-layout class="mt-5" row wrap v-if="showTransactionsTable">
      <v-flex xs12>
        <v-layout row align-center>
          <v-flex>
            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <span v-on="on"
                  >You have selected to see details for units:
                  {{ transactionTableUnitsClicked() }}</span
                >
              </template>
              <span>{{ transactionTableUnitsClicked(true) }}</span>
            </v-tooltip>
          </v-flex>
        </v-layout>
      </v-flex>
      <v-flex xs12>
        <v-data-table
          class="transactions-table"
          :headers="transactionHeaders"
          :items="transactionsData"
          :loading="transactionsLoading"
        >
          <template v-slot:items="{ item }">
            <tr
              class="timeslot-row"
              :class="item.mob_total !== item.exact_totalamount && 'has-discrepancy'"
            >
              <td>{{ item.id | ellipsis }}</td>
              <td>{{ item.exact_tag }}</td>
              <td>{{ item.exact_ordertime }}</td>
              <td>{{ item.exact_cardtype }}</td>
              <td>{{ item.exact_pickupname }}</td>
              <td>{{ item.exact_transactiontype }}</td>
              <td class="text-xs-right">{{ item.exact_totalamount | currency }}</td>
              <td>{{ item.exact_merchantname }}</td>
              <td>{{ item.exact_status }}</td>
              <td>{{ item.mob_orderid }}</td>
              <td>{{ item.mob_ordertime }}</td>
              <td>{{ item.mob_paymenttype }}</td>
              <td>{{ item.mob_pickupname }}</td>
              <td class="text-xs-right">{{ item.mob_total | currency }}</td>
            </tr>
          </template>
        </v-data-table>
      </v-flex>
    </v-layout>
  </card>
</template>

<script>
import cloneDeep from 'lodash/cloneDeep';
import { mapState } from 'vuex';
import { DateTime } from 'luxon';
import Placeholder from '@/components/Placeholder';
import Loader from '@/components/Analytics/ReconciliationCard/Loader';
import Card from '@/components/Analytics/Card';

export default {
  components: {
    Card,
    Placeholder,
    Loader,
  },
  props: {
    dates: {
      type: Object,
      required: true,
    },
    sites: {
      type: Array,
      required: true,
    },
  },
  data: () => ({
    loading: false,
    filter: '',
    summaryHeaders: [
      {
        text: 'Unit ID',
        align: 'left',
        value: 'id',
        sortable: true,
      },
      {
        text: 'Date',
        align: 'left',
        value: 'date',
        sortable: true,
      },
      {
        text: 'E-xact',
        align: 'right',
        value: 'exact',
        sortable: true,
      },
      {
        text: 'Mobile',
        align: 'right',
        value: 'mobile',
        sortable: true,
      },
      {
        text: 'Diff',
        align: 'right',
        value: 'diff',
        sortable: true,
      },
    ],
    summaryRows: [],
    summaryShowDescrepencies: false,
    showTransactionsTable: false,
    transactionsData: [],
    transactionsLoading: true,
    transactionHeaders: [
      {
        text: 'Unit ID',
        align: 'left',
        value: 'id',
      },
      {
        text: 'E-xact Tag',
        align: 'left',
        value: 'exact_tag',
        sortable: true,
      },
      {
        text: 'E-xact Order Time',
        align: 'left',
        value: 'exact_ordertime',
        sortable: true,
      },
      {
        text: 'E-xact Card Type',
        align: 'left',
        value: 'exact_cardtype',
        sortable: true,
      },
      {
        text: 'E-xact Pickup Name',
        align: 'left',
        value: 'exact_pickupname',
        sortable: true,
      },
      {
        text: 'E-xact Transaction Type',
        align: 'left',
        value: 'exact_transactiontype',
        sortable: true,
      },
      {
        text: 'E-xact Total',
        align: 'right',
        value: 'exact_totalamount',
        sortable: true,
      },
      {
        text: 'E-xact Merchant Name',
        align: 'left',
        value: 'exact_merchantname',
        sortable: true,
      },
      {
        text: 'E-xact Status',
        align: 'left',
        value: 'exact_status',
        sortable: true,
      },
      {
        text: 'Mobile Order ID',
        align: 'left',
        value: 'mob_orderid',
        sortable: true,
      },
      {
        text: 'Mobile Order Time',
        align: 'left',
        value: 'mob_ordertime',
        sortable: true,
      },
      {
        text: 'Mobile Payment Type',
        align: 'left',
        value: 'mob_paymenttype',
        sortable: true,
      },
      {
        text: 'Mobile Pickup Name',
        align: 'left',
        value: 'mob_pickupname',
        sortable: true,
      },
      {
        text: 'Mobile total',
        align: 'right',
        value: 'mob_total',
        sortable: true,
      },
    ],
    transactionTableUnits: [],
  }),
  computed: {
    ...mapState('sites', ['siteMap']),
    summaryData() {
      const search = this.filter.toLowerCase();
      const rows = search.length
        ? this.summaryRows.filter(
            (row) =>
              row.id.toLowerCase().includes(search) || row.date.toLowerCase().includes(search),
          )
        : this.summaryRows;

      if (this.summaryShowDescrepencies) {
        return rows.filter((row) => row.diff > 0);
      }

      return rows;
    },
    noOfDescrepencies() {
      return this.summaryRows.filter((row) => row.diff > 0).length;
    },
  },
  methods: {
    async fetchSummaryData() {
      this.showTransactionsTable = false;
      this.loading = true;

      try {
        await this.fakeRequest(this.generateSummaryTempData, 3000);
      } catch (e) {
        // TODO: implement error handling
        console.error(e);
      } finally {
        this.loading = false;
      }
    },
    async fetchTransactionData() {
      this.transactionsLoading = true;
      this.transactionData = [];

      try {
        await this.fakeRequest(this.generateTransactionTempData, 2000);
      } catch (e) {
        // TODO: implement error handling
        console.error(e);
      } finally {
        this.transactionsLoading = false;
      }
    },
    fakeRequest(callback, wait = 1000) {
      // simulates an API call
      return new Promise((res) => {
        window.setTimeout(() => {
          res(callback());
        }, wait);
      });
    },
    generateSummaryTempData() {
      const rows = [];
      const sites = this.sites.length
        ? this.sites
        : Object.values(this.siteMap).map((site) => site.id);

      let date = DateTime.fromFormat(this.dates.end, 'yyyy-MM-dd');
      const start = DateTime.fromFormat(this.dates.start, 'yyyy-MM-dd');

      do {
        // eslint-disable-next-line no-loop-func
        sites.forEach((site, idx) => {
          const tempSalesOne = Math.floor(Math.random() * 10000) + 100;
          const tempSalesTwo = Math.floor(Math.random() * 10000) + 100;
          const makeDescrepency = idx % 5 === 2;

          const row = {
            id: site,
            date: date.toISODate(),
            exact: tempSalesOne,
            mobile: makeDescrepency ? tempSalesTwo : tempSalesOne,
            diff: Math.abs(tempSalesOne - (makeDescrepency ? tempSalesTwo : tempSalesOne)),
          };

          rows.push(row);
        });

        date = date.minus({ days: 1 });
      } while (date >= start);

      this.summaryRows = rows;
    },
    generateTransactionTempData() {
      const items = this.transactionTableUnits.map((item) => {
        const ordersForDay = Math.floor(Math.random() * 100) + 10;

        return Array.from({ length: ordersForDay }).map((u, idx) => {
          const time = DateTime.fromFormat(item.date, 'yyyy-MM-dd');
          const orderTotal = Math.floor(Math.random() * 50) + 1;
          const makeDescrepency = idx % 5 === 2;
          return {
            id: item.id,
            exact_tag: Math.random()
              .toString(36)
              .substring(7),
            exact_ordertime: time.toISODate(),
            exact_cardtype: 'VISA',
            exact_pickupname: 'Pickup Name',
            exact_transactiontype: 'Tagged Purchase',
            exact_totalamount: orderTotal,
            exact_merchantname: 'MC-HAMMER-9099',
            exact_status: 'Approved',
            mob_orderid: Math.random()
              .toString(36)
              .substring(7),
            mob_ordertime: time.toISODate(),
            mob_paymenttype: 'VISA',
            mob_pickupname: 'Pickup Name',
            mob_total: makeDescrepency ? Math.floor(Math.random() * 50) + 1 : orderTotal,
          };
        });
      });

      this.transactionsData = items.reduce((orders, unitOrders) => [...orders, ...unitOrders], []);
      return null;
    },
    addUnitToTransactionData(item) {
      this.transactionTableUnits = [...this.transactionTableUnits, item];
      this.fetchTransactionData();
      this.showTransactionsTable = true;

      return null;
    },
    removeUnitFromTransactionData(item) {
      const index = this.transactionTableUnits.findIndex(
        (expandedItem) => expandedItem.id === item.id && expandedItem.date === item.date,
      );

      if (index === -1) {
        return null;
      }

      const updatedItems = cloneDeep(this.transactionTableUnits);
      updatedItems.splice(index, 1);
      this.transactionTableUnits = updatedItems;

      if (this.transactionTableUnits.length === 0) {
        this.showTransactionsTable = false;
      }

      this.fetchTransactionData();
      return null;
    },
    onRowClick(item) {
      const exists = this.transactionTableUnits.find(
        (expandedItem) => expandedItem.id === item.id && expandedItem.date === item.date,
      );

      if (exists) {
        return this.removeUnitFromTransactionData(item);
      }

      return this.addUnitToTransactionData(item);
    },
    transactionTableUnitsClicked(isFull) {
      if (!isFull && this.transactionTableUnits.length > 4) {
        return `${this.transactionTableUnits
          .slice(0, 4)
          .map((item) => item.id.substring(0, 5))
          .join(', ')}...`;
      }

      return this.transactionTableUnits.map((item) => item.id.substring(0, 5)).join(', ');
    },
  },
  filters: {
    ellipsis(value) {
      if (!value) return '';
      return `${value.slice(0, 3)}...`;
    },
  },
  watch: {
    dates() {
      this.fetchSummaryData();
    },
    sites() {
      this.fetchSummaryData();
    },
  },
  mounted() {
    this.fetchSummaryData();
  },
};
</script>

<style scoped>
.alert {
  border: 0;
}

.summary-table >>> .v-table .timeslot-row {
  cursor: pointer;
}

.summary-table >>> .v-table tr.timeslot-row td,
.transactions-table >>> .v-table tr.timeslot-row td {
  height: 24px;
  padding: 0 5px !important;
}

.summary-table >>> .v-table tr.timeslot-row.has-discrepancy,
.summary-table >>> .v-table tr.timeslot-row.has-discrepancy td,
.transactions-table >>> .v-table tr.timeslot-row.has-discrepancy,
.transactions-table >>> .v-table tr.timeslot-row.has-discrepancy td {
  background: var(--v-error-base);
  color: #fff;
}

.summary-table >>> .v-table .timeslot-row.has-discrepancy:hover:not(.v-datatable__expand-row),
.summary-table >>> .v-table .timeslot-row.has-discrepancy:hover:not(.v-datatable__expand-row) td,
.transactions-table >>> .v-table .timeslot-row.has-discrepancy:hover:not(.v-datatable__expand-row),
.transactions-table
  >>> .v-table
  .timeslot-row.has-discrepancy:hover:not(.v-datatable__expand-row)
  td {
  background: #e57373;
  color: #fff;
}
</style>
