var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("chart-card", {
    attrs: {
      title: "Example Bar Card",
      chartData: _vm.chartData,
      height: "350px"
    },
    on: { expanded: _vm.handleExpanded },
    scopedSlots: _vm._u([
      {
        key: "overview",
        fn: function() {
          return [
            _c("overview", { attrs: { diff: "52%", total: "$11,054.87" } })
          ]
        },
        proxy: true
      },
      {
        key: "expanded",
        fn: function() {
          return [
            _c(
              "div",
              { staticClass: "mb-3" },
              [
                _c(
                  "loading-wrapper",
                  { attrs: { loading: !_vm.expandedDataLoaded, size: 50 } },
                  [
                    _c("p", [_vm._v(_vm._s(_vm.expandedData.title))]),
                    _c("p", [_vm._v(_vm._s(_vm.expandedData.body))])
                  ]
                )
              ],
              1
            )
          ]
        },
        proxy: true
      }
    ])
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }