<template>
  <fragment>
    <v-layout class="mb-2" row>
      <v-flex
        xs12
        md2
        :class="{
          'pr-3': $vuetify.breakpoint.mdAndUp,
        }"
      >
        <date-filter :dates="dates" @datesUpdated="onDatesUpdated" />
      </v-flex>
      <v-spacer />
      <v-flex
        xs12
        md2
        class="text-xs-right"
        :class="{
          'pr-3': $vuetify.breakpoint.mdAndUp,
        }"
      >
        <site-filter
          description="Choose sites to focus the analytics on."
          :filteredSites="sites"
          :siteMap="siteMap"
          @sitesFiltered="onSitesFiltered"
        />
      </v-flex>
    </v-layout>
    <v-layout row wrap>
      <v-flex
        xs12
        md4
        :class="{
          'pr-3': $vuetify.breakpoint.mdAndUp,
        }"
      >
        <example-dynamic-bar-card />
      </v-flex>
      <v-flex
        xs12
        md5
        :class="{
          'pr-3': $vuetify.breakpoint.mdAndUp,
        }"
      >
        <reconciliation-card :dates="dates" :sites="sites" />
      </v-flex>
      <v-flex
        xs12
        md3
        :class="{
          'pr-3': $vuetify.breakpoint.mdAndUp,
        }"
      >
        <example-pie-card />
      </v-flex>
    </v-layout>
    <v-layout row wrap>
      <v-flex
        xs12
        md8
        :class="{
          'pr-3': $vuetify.breakpoint.mdAndUp,
        }"
      >
        <tracker-tags :dates="dates" :sites="sites" />
      </v-flex>
      <v-flex
        xs12
        md4
        :class="{
          'pr-3': $vuetify.breakpoint.mdAndUp,
        }"
      >
        <example-line-card />
      </v-flex>
    </v-layout>
  </fragment>
</template>

<script>
import { mapState } from 'vuex';
import { DateTime } from 'luxon';
import { SMART_ANALYTICS_DATE_PRESETS } from '@/constants';
import SiteFilter from '@/components/Filter/Sites';
import DateFilter from '@/components/Analytics/Filter/Date';
import ReconciliationCard from '@/components/Analytics/ReconciliationCard';
import TrackerTags from '@/components/Analytics/TrackerTags';
import ExampleLineCard from '@/components/Analytics/ExampleLineCard';
import ExamplePieCard from '@/components/Analytics/ExamplePieCard';
import ExampleDynamicBarCard from '@/components/Analytics/ExampleDynamicBarCard';

export default {
  components: {
    SiteFilter,
    DateFilter,
    ReconciliationCard,
    TrackerTags,
    ExampleLineCard,
    ExamplePieCard,
    ExampleDynamicBarCard,
  },
  data: () => ({
    dates: {
      start: DateTime.local().toISODate(),
      end: DateTime.local().toISODate(),
      preset: 'today',
    },
    sites: [],
  }),
  computed: {
    ...mapState('sites', ['siteMap']),
  },
  methods: {
    onSitesFiltered(sites) {
      this.sites = [...sites];
      const qsSites = sites.length ? sites.join() : undefined;

      if (qsSites) {
        localStorage.smartAnalyticsSites = qsSites;
      } else {
        localStorage.removeItem('smartAnalyticsSites');
      }

      this.$router.replace({
        name: 'smart-analytics-dashboard',
        query: { ...this.$route.query, sites: qsSites },
      });
    },
    onDatesUpdated(dates) {
      let qsDates = dates.preset;

      if (qsDates === 'custom') {
        qsDates = `${dates.start}-to-${dates.end}`;
      }

      this.$router.replace({
        name: 'smart-analytics-dashboard',
        query: { ...this.$route.query, dates: qsDates || undefined },
      });

      localStorage.smartAnalyticsDates = qsDates;

      this.dates = {
        ...dates,
      };
    },
    formatDatesObj(dateStr) {
      // Nothing passed, return default
      if (!dateStr) {
        return this.dates;
      }

      // Custom range
      if (dateStr.includes('-to-')) {
        const dates = dateStr.split('-to-');
        return {
          start: DateTime.fromISO(dates[0]).toISODate(),
          end: DateTime.fromISO(dates[1]).toISODate(),
          preset: SMART_ANALYTICS_DATE_PRESETS.custom.value,
        };
      }

      // Preset
      const [start, end] = SMART_ANALYTICS_DATE_PRESETS[dateStr].range();
      return {
        start,
        end,
        preset: dateStr,
      };
    },
  },
  created() {
    const { sites: qsSites, dates: qsDates } = this.$route.query;
    const { smartAnalyticsSites: lsSites, smartAnalyticsDates: lsDates } = localStorage;

    // Query String in URL takes precedent over localStorage
    if (qsSites || qsDates) {
      this.sites = qsSites ? qsSites.split(',') : [];
      localStorage.smartAnalyticsSites = qsSites;

      this.dates = this.formatDatesObj(qsDates);
      localStorage.smartAnalyticsDates = qsDates;

      return;
    }

    // Local storage
    if (lsSites || lsDates) {
      this.sites = lsSites ? lsSites.split(',') : [];
      this.dates = this.formatDatesObj(lsDates);

      this.$router.replace({
        name: 'smart-analytics-dashboard',
        query: { dates: lsDates || undefined, sites: lsSites || undefined },
      });
    }
  },
};
</script>
