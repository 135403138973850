<template>
  <v-layout row wrap class="overview-row">
    <v-flex xs6>
      <span
        :class="{
          headline: $vuetify.breakpoint.smAndDown,
          'display-1': $vuetify.breakpoint.mdAndUp,
        }"
      >
        <slot name="total">
          {{ total }}
        </slot>
      </span>
    </v-flex>
    <v-flex xs6 class="text-xs-right">
      <span
        :class="{
          headline: $vuetify.breakpoint.smAndDown,
          'display-1': $vuetify.breakpoint.mdAndUp,
        }"
      >
        <slot name="diff">
          {{ diff }}
        </slot>
      </span>
    </v-flex>
  </v-layout>
</template>

<script>
import merge from 'lodash/merge';

export default {
  data: () => ({
    // TODO: default style
    defaultChartOptions: {},
  }),
  computed: {
    combinedOptions() {
      return merge(this.defaultChartOptions, this.chartData.options);
    },
  },
  props: {
    diff: {
      type: String || Number,
      required: false,
      default: '',
    },
    total: {
      type: String || Number,
      required: false,
      default: '',
    },
  },
};
</script>

<style scoped>
.diff {
  text-align: right;
}

.overview-row {
  margin-bottom: 16px;
}
</style>
