<template>
  <fragment>
    <slot v-if="loading" name="loading"></slot>
    <slot v-if="!loading"></slot>
  </fragment>
</template>

<script>
export default {
  props: {
    loading: {
      type: Boolean,
      default: false,
    },
  },
};
</script>
