<template>
  <chart-card
    title="Example Line Card"
    :chartData="chartData"
    :height="$vuetify.breakpoint.smAndDown ? '200px' : undefined"
  >
    <template #overview>
      <overview diff="+90" total="148"></overview>
    </template>
  </chart-card>
</template>

<script>
import Overview from '@/components/Analytics/Card/Overview';
import ChartCard from '@/components/Analytics/Card/Chart';

export default {
  components: {
    ChartCard,
    Overview,
  },
  data: () => ({
    chartData: {
      series: [
        {
          name: 'Desktops',
          data: [10, 41, 35, 51, 49, 62, 69, 91, 148],
        },
      ],
      options: {
        chart: {
          height: 350,
          type: 'line',
        },
        dataLabels: {
          enabled: false,
        },
        stroke: {
          curve: 'straight',
        },
        title: {
          text: 'Product Trends by Month',
          align: 'left',
        },
        grid: {
          row: {
            colors: ['#f3f3f3', 'transparent'], // takes an array which will be repeated on columns
            opacity: 0.5,
          },
        },
        xaxis: {
          categories: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep'],
        },
      },
    },
  }),
};
</script>
