<template>
  <card title="Tracker Tags">
    <v-layout row justify-end>
      <v-btn color="primary" @click="addNewTrackerTag" small depressed>
        <v-icon left small>mdi-plus</v-icon>
        New Tracker Tag
      </v-btn>
    </v-layout>
  </card>
</template>

<script>
import Card from '@/components/Analytics/Card';

export default {
  components: {
    Card,
  },
  props: {
    dates: {
      type: Object,
      required: true,
    },
    sites: {
      type: Array,
      required: true,
    },
  },
  methods: {
    addNewTrackerTag() {
      this.$router.push({ name: 'add-tracker-tag' });
    },
  },
};
</script>
