<template>
  <chart-card
    title="Example Pie Card"
    :chartData="chartData"
    :height="$vuetify.breakpoint.smAndDown ? '400px' : undefined"
  >
  </chart-card>
</template>

<script>
import ChartCard from '@/components/Analytics/Card/Chart';

export default {
  components: {
    ChartCard,
  },
  data: () => ({
    chartData: {
      series: [44, 55, 13, 43, 22],
      options: {
        chart: {
          type: 'pie',
        },
        labels: ['Team A', 'Team B', 'Team C', 'Team D', 'Team E'],
        title: {
          text: 'Sales by Team',
        },
        responsive: [
          {
            breakpoint: 480,
            options: {
              chart: {
                width: 200,
                height: 400,
              },
              legend: {
                position: 'bottom',
              },
            },
          },
        ],
      },
    },
  }),
};
</script>
