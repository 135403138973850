<template>
  <v-dialog
    v-model="dialog"
    width="800px"
    max-width="100vw"
    @keydown.esc="onCancel"
    @input="onDialogChange"
  >
    <template v-slot:activator="{ on }">
      <v-flex xs12>
        <v-btn class="ml-0" v-on="on">
          <v-icon class="mr-2">
            mdi-filter-variant
          </v-icon>
          Filter
        </v-btn>
      </v-flex>
    </template>
    <v-card>
      <v-layout row wrap>
        <v-flex xs12>
          <v-card-title class="H4-Secondary-Center px-4 pt-4">Filter by Site</v-card-title>
          <v-card-text v-if="description" class="Body-2-Selected-On-Surface-Medium-Emphasis-Left">
            {{ description }}
          </v-card-text>
        </v-flex>
      </v-layout>
      <v-card-text>
        <v-layout row wrap>
          <v-flex xs12 md8>
            <v-text-field
              label="Search Sites"
              prepend-icon="mdi-magnify"
              v-model="search"
              clearable
            />
          </v-flex>
        </v-layout>
        <v-flex xs12>
          <v-layout row wrap class="mt-4">
            <v-flex xs12 md6>
              <p class="H6-Secondary-Left">All Sites</p>
              <v-list
                class="list-panel"
                :class="{
                  'mr-2': $vuetify.breakpoint.mdAndUp,
                  'mb-4': $vuetify.breakpoint.smAndDown,
                }"
              >
                <v-list-tile>
                  <v-list-tile-action>
                    <v-checkbox
                      :disabled="isDisabled"
                      v-model="isSelectAll"
                      :value="isSelectAll"
                      @change="selectAll"
                    />
                  </v-list-tile-action>
                  <v-list-tile-content>
                    <v-list-tile-title>Select All</v-list-tile-title>
                  </v-list-tile-content>
                </v-list-tile>
                <v-list-tile
                  v-for="site of filterBy(availableSites, search, 'name')"
                  :key="site.id"
                >
                  <v-list-tile-action>
                    <v-checkbox
                      v-model="isChecked[site.id]"
                      @change="addSite(site)"
                      :value="isChecked[site.id]"
                      :label="site.name"
                    />
                  </v-list-tile-action>
                </v-list-tile>
              </v-list>
            </v-flex>
            <v-flex xs12 md6>
              <p class="H6-Secondary-Left">Sites Selected ({{ selectedSites.length }})</p>
              <v-list class="list-panel">
                <v-list-tile>
                  <v-list-tile-action>
                    <v-icon :disabled="isDisabled" @click="removeAll">mdi-close-circle</v-icon>
                  </v-list-tile-action>
                  <v-list-tile-content>
                    <v-list-tile-title>Remove All</v-list-tile-title>
                  </v-list-tile-content>
                </v-list-tile>
                <v-list-tile v-for="site of filterBy(selectedSites, search, 'name')" :key="site.id">
                  <v-list-tile-action>
                    <v-icon @click="removeSite(site)">mdi-close-circle</v-icon>
                  </v-list-tile-action>
                  <v-list-tile-content>
                    <v-list-tile-title>{{ site.name }}</v-list-tile-title>
                  </v-list-tile-content>
                </v-list-tile>
              </v-list>
            </v-flex>
          </v-layout>
        </v-flex>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="primary" flat @click="onCancel">Cancel</v-btn>
        <v-btn color="primary" flat @click="onApply">Apply</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import filters from 'vue2-filters';

export default {
  mixins: [filters.mixin],
  props: {
    description: {
      type: String,
      required: false,
    },
    filteredSites: {
      type: Array,
      required: true,
    },
    siteMap: {
      type: Object,
      required: true,
    },
  },
  data: () => ({
    dialog: false,
    search: '',
    selectedSites: [],
  }),
  computed: {
    availableSites() {
      return Object.values(this.siteMap);
    },
    isChecked() {
      return this.selectedSites.reduce((sites, site) => {
        sites[site.id] = true;
        return sites;
      }, {});
    },
    isDisabled() {
      return !!this.search;
    },
    isSelectAll: {
      get() {
        return this.selectedSites.length === Object.values(this.siteMap).length;
      },
      set() {},
    },
  },
  methods: {
    selectAll() {
      if (this.isSelectAll) {
        this.removeAll();
        return null;
      }

      this.selectedSites = Object.values(this.siteMap).map((site) => ({
        id: site.id,
        name: site.name,
      }));

      return null;
    },
    removeAll() {
      this.selectedSites = [];
      return null;
    },
    onApply() {
      const ids = this.selectedSites.map((site) => site.id);
      this.$emit('sitesFiltered', ids);
      this.selectedSites = [];
      this.dialog = false;
    },
    onCancel() {
      this.selectedSites = [];
      this.dialog = false;
    },
    onDialogChange(open) {
      if (open) {
        this.selectedSites = this.filteredSites.map((id) => ({
          id,
          name: this.siteMap[id] ? this.siteMap[id].name : '',
        }));
      }
    },
    addSite(site) {
      if (this.selectedSites.find((i) => i.id === site.id)) {
        this.removeSite(site);
        return null;
      }
      this.selectedSites.push({
        id: site.id,
        name: site.name,
      });

      return this.selectedSites;
    },
    removeSite(site) {
      const updatedArr = [...this.selectedSites];
      const index = updatedArr.findIndex((i) => i.id === site.id);

      if (index === -1) {
        return null;
      }

      updatedArr.splice(index, 1);
      this.selectedSites = updatedArr;
      return this.selectedSites;
    },
  },
};
</script>

<style scoped>
.list-panel {
  border: 1px solid #000;
  height: 300px;
  overflow-y: scroll;
}

.list-panel >>> .v-input__slot {
  margin-bottom: 0;
}
</style>
