var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "card",
    {
      attrs: { title: _vm.title },
      on: { expanded: _vm.onExpand },
      scopedSlots: _vm._u(
        [
          {
            key: "overview",
            fn: function() {
              return [
                _vm._t("overview", null, {
                  overview: _vm.$scopedSlots.overview
                })
              ]
            },
            proxy: true
          },
          {
            key: "expanded",
            fn: function() {
              return [
                _vm._t("expanded", null, {
                  expanded: _vm.$scopedSlots.expanded
                })
              ]
            },
            proxy: true
          }
        ],
        null,
        true
      )
    },
    [
      [
        _c("apexchart", {
          staticClass: "chart",
          attrs: {
            width: _vm.width,
            height: _vm.height,
            options: _vm.combinedOptions,
            series: _vm.chartData.series
          }
        })
      ]
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }