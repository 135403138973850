import { VFlex } from 'vuetify/lib/components/VGrid';
import { VLayout } from 'vuetify/lib/components/VGrid';
import { VSpacer } from 'vuetify/lib/components/VGrid';

var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "fragment",
    [
      _c(
        VLayout,
        { staticClass: "mb-2", attrs: { row: "" } },
        [
          _c(
            VFlex,
            {
              class: {
                "pr-3": _vm.$vuetify.breakpoint.mdAndUp
              },
              attrs: { xs12: "", md2: "" }
            },
            [
              _c("date-filter", {
                attrs: { dates: _vm.dates },
                on: { datesUpdated: _vm.onDatesUpdated }
              })
            ],
            1
          ),
          _c(VSpacer),
          _c(
            VFlex,
            {
              staticClass: "text-xs-right",
              class: {
                "pr-3": _vm.$vuetify.breakpoint.mdAndUp
              },
              attrs: { xs12: "", md2: "" }
            },
            [
              _c("site-filter", {
                attrs: {
                  description: "Choose sites to focus the analytics on.",
                  filteredSites: _vm.sites,
                  siteMap: _vm.siteMap
                },
                on: { sitesFiltered: _vm.onSitesFiltered }
              })
            ],
            1
          )
        ],
        1
      ),
      _c(
        VLayout,
        { attrs: { row: "", wrap: "" } },
        [
          _c(
            VFlex,
            {
              class: {
                "pr-3": _vm.$vuetify.breakpoint.mdAndUp
              },
              attrs: { xs12: "", md4: "" }
            },
            [_c("example-dynamic-bar-card")],
            1
          ),
          _c(
            VFlex,
            {
              class: {
                "pr-3": _vm.$vuetify.breakpoint.mdAndUp
              },
              attrs: { xs12: "", md5: "" }
            },
            [
              _c("reconciliation-card", {
                attrs: { dates: _vm.dates, sites: _vm.sites }
              })
            ],
            1
          ),
          _c(
            VFlex,
            {
              class: {
                "pr-3": _vm.$vuetify.breakpoint.mdAndUp
              },
              attrs: { xs12: "", md3: "" }
            },
            [_c("example-pie-card")],
            1
          )
        ],
        1
      ),
      _c(
        VLayout,
        { attrs: { row: "", wrap: "" } },
        [
          _c(
            VFlex,
            {
              class: {
                "pr-3": _vm.$vuetify.breakpoint.mdAndUp
              },
              attrs: { xs12: "", md8: "" }
            },
            [
              _c("tracker-tags", {
                attrs: { dates: _vm.dates, sites: _vm.sites }
              })
            ],
            1
          ),
          _c(
            VFlex,
            {
              class: {
                "pr-3": _vm.$vuetify.breakpoint.mdAndUp
              },
              attrs: { xs12: "", md4: "" }
            },
            [_c("example-line-card")],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }