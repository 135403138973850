import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VFlex } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VLayout } from 'vuetify/lib/components/VGrid';

var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    VCard,
    { staticClass: "card" },
    [
      _c(VCardTitle, { staticClass: "title" }, [
        _c("span", { staticClass: "card-title" }, [_vm._v(_vm._s(_vm.title))])
      ]),
      _c(
        VCardText,
        [
          _vm.$slots.overview
            ? _c(
                VLayout,
                { attrs: { row: "" } },
                [_c(VFlex, [_vm._t("overview")], 2)],
                1
              )
            : _vm._e(),
          _c(
            VLayout,
            { attrs: { row: "" } },
            [_c(VFlex, [_vm._t("default")], 2)],
            1
          )
        ],
        1
      ),
      _vm.$slots.expanded
        ? _c(
            VCardActions,
            {
              staticClass: "actions",
              attrs: { transition: "slide-y-transition" }
            },
            [
              _c(
                VLayout,
                { attrs: { row: "" } },
                [_c(VFlex, [_vm.expand ? _vm._t("expanded") : _vm._e()], 2)],
                1
              ),
              _c(
                VLayout,
                { attrs: { row: "", "justify-center": "" } },
                [
                  _c(
                    VBtn,
                    {
                      staticClass: "expand-button",
                      attrs: { flat: "", small: "" },
                      on: { click: _vm.onExpand }
                    },
                    [
                      _vm._v(
                        "\n        " +
                          _vm._s(_vm.expand ? "Close" : "Show All") +
                          "\n        "
                      ),
                      _c(VIcon, [
                        _vm._v(
                          _vm._s(_vm.expand ? "mdi-menu-up" : "mdi-menu-down")
                        )
                      ])
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }