import { VFlex } from 'vuetify/lib/components/VGrid';
import { VLayout } from 'vuetify/lib/components/VGrid';

var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    VLayout,
    { staticClass: "overview-row", attrs: { row: "", wrap: "" } },
    [
      _c(VFlex, { attrs: { xs6: "" } }, [
        _c(
          "span",
          {
            class: {
              headline: _vm.$vuetify.breakpoint.smAndDown,
              "display-1": _vm.$vuetify.breakpoint.mdAndUp
            }
          },
          [
            _vm._t("total", [
              _vm._v("\n        " + _vm._s(_vm.total) + "\n      ")
            ])
          ],
          2
        )
      ]),
      _c(VFlex, { staticClass: "text-xs-right", attrs: { xs6: "" } }, [
        _c(
          "span",
          {
            class: {
              headline: _vm.$vuetify.breakpoint.smAndDown,
              "display-1": _vm.$vuetify.breakpoint.mdAndUp
            }
          },
          [
            _vm._t("diff", [
              _vm._v("\n        " + _vm._s(_vm.diff) + "\n      ")
            ])
          ],
          2
        )
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }